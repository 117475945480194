<template>
  <div class="content">
    <h1>Log Out</h1>

    <div class="text-center">
      <p>Are you sure you want to log out?</p>

      <b-button
        variant="primary"
        :disabled="buttonClicked"
        @click="logOut"
      >
        Yes, Log Out
      </b-button>

      <b-button
        type="button"
        variant="secondary"
        :disabled="buttonClicked"
        @click="$router.push({name: 'home'})"
      >
        Cancel
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LogOut',
  data() {
    return {
      buttonClicked: false,
    };
  },
  metaInfo: {
    title: 'Log Out',
  },
  methods: {
    logOut() {
      this.buttonClicked = true;
      this.logUserOut();
    },
  },
};
</script>
